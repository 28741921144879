import { isEmailValid, onLoad } from './libs/helpers';

let subscribeEmailInputEl, subscribeEmailValidationEl;

onLoad(() => {
  prepareEmailSubscriptionElements();
});

function prepareEmailSubscriptionElements() {
  subscribeEmailInputEl = document.querySelector('#subscribeEmailInput');
  subscribeEmailValidationEl = subscribeEmailInputEl.parentElement.querySelector('.validation');
  subscribeEmailInputEl.addEventListener('keyup', e => {
    if (e.keyCode === 13) {
      // keyCode of Enter is 13
      window['subscribeEmail']();
    }
  });
}

window['subscribeEmail'] = async () => {
  const email = subscribeEmailInputEl.value.trim();
  if (isEmailValid(email)) {
    subscribeEmailValidationEl.style.display = 'none';
  } else {
    subscribeEmailValidationEl.style.display = 'block';
    return;
  }
  updateSubscribeButtonState('in-progress');
  const response = await fetch('/api/subscribeEmail', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email }),
  });
  const json = await response.json();
  if (response.status === 200 && json.success) {
    updateSubscribeButtonState('subscribed');
    return json;
  } else {
    updateSubscribeButtonState('error');
    console.error(json);
    return json;
  }
};

// Update button to "Subscribing..." and "✅ Email subscribed"
function updateSubscribeButtonState(state) {
  const buttonElement = document.querySelector<HTMLButtonElement>('#subscribeEmailButton');
  switch (state) {
    case 'in-progress': {
      buttonElement.innerHTML = 'Subscribing <i class="fa fa-spin fa-circle-o-notch"></i>';
      buttonElement.style.pointerEvents = 'none'; // to avoid multiple clicks
      buttonElement.style.cursor = 'default';
      buttonElement.style.filter = 'brightness(150%)'; // to make look like disabled
      break;
    }
    case 'subscribed': {
      buttonElement.style.pointerEvents = 'auto';
      buttonElement.style.cursor = 'pointer';
      buttonElement.style.filter = 'brightness(100%)';
      buttonElement.innerHTML = `✅ Email subscribed`;
      break;
    }
    case 'error': {
      buttonElement.style.pointerEvents = 'auto';
      buttonElement.style.cursor = 'pointer';
      buttonElement.style.filter = 'brightness(100%)';
      buttonElement.innerHTML = `❌ Error`;
      break;
    }
  }
}
